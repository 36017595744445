<template>
  <b-row class="pt-4 pb-4 bottom-border min-height-300 vendor-container">

    <b-col sm="4">
        <b-img
          fluid
          :src="logo"
          class="mobile-padding"
        />
    </b-col>
    <b-col sm="8" class="mobile-seperator">
      <h2 class="d-none d-lg-block" style="font-size: 20px; font-weight: 600; margin-bottom: 0px;">{{ vendor.display_name | capitalizeAll }}</h2>
      <!-- <a
        :href="lxPdf"
        v-show="showDigAdLxPackageLink"
        target="_BLANK"
        style="font-size: 13px; font-weight: 600; padding-bottom: 10px; text-decoration: none;"
      >
        LX Package Provider
      </a> -->
      <p class="pre-line" style="margin-top: 10px;">{{ vendor.description_1 }}</p>
      <p v-show="showFeeNotice" class="small font-weight-bold">* Additional $149 Platform Fee will go into effect August 1st.</p>
      <button @click="launchEnrollment()" type="button" class="btn btn-outline-primary">
        <b-icon-boxArrowUpRight style="width: 22px; height: 22px; margin-bottom: -3px; margin-right: 3px;"></b-icon-boxArrowUpRight>
        ENROLL
      </button>
      <button @click="playVideo(video)" v-show="vendor.video" type="button" class="btn btn-outline-light desktop-ml-10">
        <b-icon-playFill style="width: 30px; height: 30px; margin-bottom: -6px;"></b-icon-playFill>
        VIDEO
      </button>
      <button @click="openPdf()" v-show="vendor.pdf" type="button" class="btn btn-outline-light desktop-ml-10">
        <b-icon-file-earmark style="width: 30px; height: 30px; margin-bottom: -6px; padding-top: 2px; padding-bottom: 2px;"></b-icon-file-earmark>
        INFO SHEET
      </button>
    </b-col>
    
  </b-row>
</template>

<script>

export default {

  name: "vendor",

  components: {

  },

  props: {
    vendor: {},
    activeCategory: {},
  },

  data() {
    return {

    }
  },

  methods: {
    
    openPdf() {
      window.open(this.pdf);
    },

    launchEnrollment() {
      window.scrollTo(0,0);
      this.$store.commit("toggleModal");
      this.$store.commit("toggleEnrollment");
    },

    playVideo() {
      window.scrollTo(0,0);
      this.$store.commit("setVideoUrl", this.vendor.video);
      this.$store.commit("toggleModal");
      this.$store.commit("toggleVideo");
    },

  },

  computed: {

    logo(){
      return process.env.VUE_APP_FILES_PATH + this.vendor.logo;
    },

    pdf(){
      return process.env.VUE_APP_FILES_PATH + this.vendor.pdf;
    },

    lxPdf() {
      return '/lx_package_one_sheet_rev2.pdf'
    },

    showFeeNotice(){

      if(this.vendor.vendor_id === '130405' && this.activeCategory.category_id === '4649') {
        return true;
      }

      return false;
    },

    // showDigAdLxPackageLink(){
    //   if(this.$store.state.lxDigAdVendors.includes(this.vendor.vendor_id) && this.activeCategory.category_id === '4649') {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
    
  }
};

</script>

<style scoped>

  .min-height-300 {
    min-height: 250px;
  }

  .btn-outline-light {
    padding-top: 5px;
    margin-top: 10px;
    background-color:rgba(0, 0, 0, .6);
    border: 1.4px solid white;
    font-weight: 400;
    border-radius: 30px;
    transition: none;
  }

  .btn-outline-light:hover {
    background-color:rgba(0, 0, 0, .8);
    color: white;
    transition: none;
  }

  .btn-outline-light:active {
    background-color:rgba(0, 0, 0, .8) !important;
    color: white !important;
    transition: none;
  }

  .btn-outline-primary {
    padding-top: 5px;
    margin-top: 10px;
    background-color:rgb(0, 124, 192);
    border: 1.4px solid white;
    font-weight: 400;
    border-radius: 30px;
    transition: none;
    color: white;
  }

  .btn-outline-primary:hover {
    background-color: rgb(23, 92, 142);
    color: white;
    transition: none;
  }

  .btn-outline-primary:active {
    background-color:rgba(0, 0, 0, .8) !important;
    color: white !important;
    transition: none;
  }

  .bottom-border {
    border-bottom: 1px solid grey;
  }

  .bottom-border:last-child {
    border-bottom: none;
    margin-bottom: 5px;
  }

  .vendor-container {
    margin-top: 25px;
  }



  @media screen and (min-width: 769px) {

    .desktop-ml-10 {
      margin-left: 10px;
    }

    .btn-outline-light {
      margin-bottom: 30px;
    }
    
    .btn-outline-primary {
      margin-bottom: 30px;
    }

  }

  @media screen and (max-width: 768px) {

    .mobile-width-tweak {
      padding: 0px;
    }

    .btn {
      width: 100%;
    }

    .mobile-seperator {
      padding-bottom: 25px;
    }

    .mobile-padding {
      padding: 5px;
    }

    .button-outline-light {
      margin-bottom: 0px;
    }

    .vendor-container {
      margin-top: 0px;
    }


  }
    

</style>
