<template>
  <div>
    <the-modal class="col-lg-8 offset-xl-2" style="" v-show="videoActive">
      <b-icon-x id="close-x" @click="closeEverything"
        style="position: absolute; color: white; z-index: 150; right: -50px; height: 35px; width: 35px; opacity: .5;"></b-icon-x>
      <video class="embed-responsive embed-responsive-16by9 video" controls autoplay ref="vendorVideo"
        :src="videoActiveUrl" type='video/mp4'>
      </video>
    </the-modal>

    <the-modal class="col-lg-4 offset-xl-4 w-100" v-show="enrollmentActive">
      <b-icon-x id="close-x" @click="closeEverything"
        style="position: absolute; color: white; z-index: 150; right: -50px; height: 35px; width: 35px; opacity: .5;"></b-icon-x>
      <div class="enrollment p-4 w-100">
        <b-img fluid src="@/assets/jdp_autodata_logo.png"
          style="margin-top:15px; padding-left: 25px; padding-right: 25px; max-width: 300px;" class="mr-35-desktop" />
        <h1 style="font-size: 24px; font-weight: 600; margin-top: 50px;">Now launching the enrollment site...</h1>
        <p class="p-3">You'll need to be a Honda dealer to access the password protected site. If you are a Honda dealer
          and do not have your password, please contact either your Dealer Principal or District Manager.</p>

        <button @click="openEnrollment" type="button" class="btn btn-outline-primary">
          Ok, got it!
        </button><br>
        <button @click="closeEverything" type="button" class="btn btn-outline-light">
          Cancel
        </button>
      </div>
    </the-modal>

    <div class="d-flex justify-content-center content">
      <b-row class="w-100" style="max-width: 1200px;">
        <b-col md="6">
          <h1 class="mt-4" style="font-size: 25px;">{{ category.display_name | capitalizeAll }}</h1>
          <h2 style="font-size: 18px;">{{ category.description_short }}</h2>
        </b-col>
        <b-col class="demo-btn-container" md="6">
          <button @click="openDemoRequestUrl" type="button" class="btn btn-outline-primary">
            Schedule Your Demo Today
          </button>
        </b-col>

        <b-col md="4">
          <b-img fluid src="@/assets/hds_certified_emblem.png"
            style="margin-top:25px; padding-left: 25px; padding-right: 25px;" class="mr-35-desktop emblem" />
        </b-col>

        <b-col md="8">
          <p class="mt-4 pre-line">{{ category.description_1 }}</p>
          <p class="mt-4 pre-line">{{ category.description_2 }}</p>
          <p style="font-weight: 600;"><a :href="category.demoRequestUrl" target="blank"></a></p>
          <Vendor :class="vendor.active == true ? 'nothing' : 'd-none'" v-for="(vendor, index) in vendorsRandom"
            :key="index" :vendor="vendor" :activeCategory="category"></Vendor>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>

import Vendor from '@/components/Vendor.vue'
import TheModal from '@/components/TheModal.vue'
import { BIconX } from 'bootstrap-vue'

export default {
  name: 'category',
  components: {
    Vendor,
    TheModal,
    BIconX,
  },

  watch: {
    '$route.params.categorySlug': function () {
      this.getCategory();
      this.getVendors();
    },

    videoActive: function () {
      if (!this.videoActive) {
        this.stopVideo();
      } else {
        this.playVideo();
      }
    }
  },

  created() {
    this.getCategory();
    this.getVendors();
  },

  data() {
    return {
      category: {},
      vendors: {},
      errors: {},
      demoRequestUrls: this.$store.state.data.demoRequestUrls
    }
  },

  metaInfo() {
    return {
      title: this.category.display_name
    }
  },

  methods: {

    goToFixedOpsSignup() {
      this.$router.push('/fixed-ops-signup');
    },

    getCategory() {
      this.$store.commit('generateApiCreds');
      this.$axios
        .get(process.env.VUE_APP_API_PATH + 'categories/slug/' + this.$route.params.categorySlug, {
          auth: {
            username: this.$store.state.auth.apiUsername,
            password: this.$store.state.auth.apiPassword,
          }
        })
        .then(resp => {
          if (resp.status == 200) {
            // lets grab our demo request url too - not held in the db right now
            resp.data.demoRequestUrl = this.getDemoRequestUrl(resp.data.category_id);
            this.category = resp.data;
          } else {
            // console.log('Our getCategory method did not return anything. Assuming this is not a valid category, and redirecting to page not found.');
            this.$router.push('/page-not-found');
          }
        })
      // .catch(err => {
      // console.log(err);
      // });
    },

    getVendors() {
      this.$store.commit('generateApiCreds');
      this.$axios
        .get(process.env.VUE_APP_API_PATH + 'vendors/category/' + this.$route.params.categorySlug, {
          auth: {
            username: this.$store.state.auth.apiUsername,
            password: this.$store.state.auth.apiPassword,
          }
        })
        .then(resp => {
          
          // we could have a leftover vendor_id in here... should get it out of here beforehand but whatever...
          const vendors = {};
          Object.keys(resp.data).forEach((key) => {
            if (resp.data[key] !== null) {
              vendors[key] = resp.data[key];
            }
          });

          this.vendors = vendors;
        })
    },

    stopVideo() {
      this.vendorVideo.pause();
      this.vendorVideo.currentTime = 0;
    },

    playVideo() {
      this.vendorVideo.play();
    },

    openEnrollment() {
      window.open('https://login.smartdealerportal.com/login');
      this.closeEverything();
    },

    openDemoRequestUrl() {
      window.open(this.category.demoRequestUrl);
      this.closeEverything();
    },

    closeEverything() {
      this.$store.commit("closeEverything");
    },

    getDemoRequestUrl(category_id) {
      let data = this.demoRequestUrls.filter(item => item.category_id == category_id);
      return data[0].url;
    }

  },

  computed: {

    pageTitle() {
      return this.$route.params.categorySlug.replace('-', ' ');
    },

    videoActive() {
      return this.$store.state.videoActive ? true : false;
    },

    enrollmentActive() {
      return this.$store.state.enrollmentActive ? true : false;
    },

    videoActiveUrl() {
      return this.$store.state.videoActiveUrl;
    },

    vendorVideo() {
      return this.$refs.vendorVideo;
    },

    vendorsRandom() {
      let vendors = Object.values(this.vendors);
      return vendors.sort(function () { return 0.5 - Math.random() });
    },

    isDigAd() {
      if (this.$route.params.categorySlug === 'digital-advertising') {
        return true;
      } else {
        return false;
      }
    },
    /*
        isLxDigAdVendor(vendor_id) {
          if(this.$route.params.categorySlug === 'digital-advertising') {
            if(this.$store.state.lxDigAdVendors.includes(vendor_id)) {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
    
    
        }
        */

  }
}
</script>

<style scoped>
.demo-btn-container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.hover-fade:hover {
  opacity: .8;
  cursor: pointer;
  transition: all 100ms ease;
}

.video {
  position: absolute;
  z-index: 250;
  background-color: black;
  border-radius: 5px;
}

.video:focus {
  outline: none;
}

.enrollment {
  position: absolute;
  text-align: center;
  z-index: 250;
  color: #212529;
  background-color: white;
  border-radius: 5px;
}

.enrollment:focus {
  outline: none;
}

#close-x:hover {
  cursor: pointer;
  opacity: 1;
}

.btn-outline-light {
  padding-top: 5px;
  margin-top: 10px;
  margin-bottom: 20px;
  background-color: rgba(0, 0, 0, .1);
  border: 1.4px solid white;
  font-weight: 400;
  border-radius: 30px;
  transition: none;
  width: 250px;
  color: black;
}

.btn-outline-light:hover {
  background-color: rgba(0, 0, 0, .2);
  transition: none;
}

.btn-outline-light:active {
  background-color: rgba(0, 0, 0, .2) !important;
  color: white !important;
  transition: none;
}

.btn-outline-primary {
  padding-top: 5px;
  margin-top: 25px;
  background-color: rgb(0, 124, 192);
  border: 1.4px solid white;
  font-weight: 400;
  border-radius: 30px;
  transition: none;
  color: white;
  width: 250px;
}

.btn-outline-primary:hover {
  background-color: rgb(23, 92, 142);
  border-color: white;
  color: white;
  transition: none;
}

.btn-outline-primary:active {
  background-color: rgba(0, 0, 0, .5) !important;
  color: white !important;
  transition: none;
}


@media screen and (min-width: 769px) {
  .mr-35-desktop {
    margin-right: 35px;
  }
}


@media screen and (max-width: 768px) {

  .demo-btn-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .content {
    margin-top: 0px;
    padding-top: 110px;
  }

  .photo {
    margin-right: 0px;
  }

  .video {
    position: fixed;
    right: 0px;
    border-radius: 0px;
    margin-top: 25%;
    z-index: 400;
  }

  .enrollment {
    left: 0px;
    width: 100%;
    position: fixed;
    border-radius: 0px;
    margin-top: 25%;
    z-index: 400;
  }

  #close-x {
    display: none;
  }

}
</style>